<template>
  <section id="feather-icons">
    <div class="icon-search-wrapper my-3 mx-auto">
      <b-form-group>
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            id="icons-search"
            v-model="seachQuery"
            placeholder="查找车手"
          />
        </b-input-group>
      </b-form-group>
    </div>
    <b-card>
      <b-card-header class="p-1">
        <div class="d-flex align-items-center">
          <b-img
            :src="require('@/assets/images/license/PRO-24.png')"
          />
          <h5 class="ml-2">
            Pro赛照
          </h5>
        </div>
      </b-card-header>
      <b-card-body>
        <b-badge
          v-for="driver in filterDrivers.filter(x => x.license === 'Pro')"
          :key="driver.id"
          class="m-1"
          href="#"
          variant="level1"
        >
          <h-link
            :id="driver.id"
            :text="driver.name"
            to="driver"
            type="driver"
          />
        </b-badge>
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-header class="p-1">
        <div class="d-flex align-items-center">
          <b-img
            :src="require('@/assets/images/license/A-24.png')"
          />
          <h5 class="ml-2">
            A赛照
          </h5>
        </div>
      </b-card-header>
      <b-card-body>
        <b-badge
          v-for="driver in filterDrivers.filter(x => x.license === 'A')"
          :key="driver.id"
          class="m-1"
          href="#"
          variant="danger"
        >
          <h-link
            :id="driver.id"
            :text="driver.name"
            to="driver"
            type="driver"
          />
        </b-badge>
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-header class="p-1">
        <div class="d-flex align-items-center">
          <b-img
            :src="require('@/assets/images/license/B-24.png')"
          />
          <h5 class="ml-2">
            B赛照
          </h5>
        </div>
      </b-card-header>
      <b-card-body>
        <b-badge
          v-for="driver in filterDrivers.filter(x => x.license === 'B')"
          :key="driver.id"
          class="m-1"
          href="#"
          variant="warning"
        >
          <h-link
            :id="driver.id"
            :text="driver.name"
            to="driver"
            type="driver"
          />
        </b-badge>
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-header class="p-1">
        <div class="d-flex align-items-center">
          <b-img
            :src="require('@/assets/images/license/C-24.png')"
          />
          <h5 class="ml-2">
            C赛照
          </h5>
        </div>
      </b-card-header>
      <b-card-body>
        <b-badge
          v-for="driver in filterDrivers.filter(x => x.license === 'C')"
          :key="driver.id"
          class="m-1"
          href="#"
          variant="info"
        >
          <h-link
            :id="driver.id"
            :text="driver.name"
            to="driver"
            type="driver"
          />
        </b-badge>
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-header class="p-1">
        <div class="d-flex align-items-center">
          <b-img
            :src="require('@/assets/images/license/D-24.png')"
          />
          <h5 class="ml-2">
            D赛照
          </h5>
        </div>
      </b-card-header>
      <b-card-body>
        <b-badge
          v-for="driver in filterDrivers.filter(x => x.license === 'D')"
          :key="driver.id"
          class="m-1"
          href="#"
          variant="success"
        >
          <h-link
            :id="driver.id"
            :text="driver.name"
            to="driver"
            type="driver"
          />
        </b-badge>
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-header class="p-1">
        <div class="d-flex align-items-center">
          <b-img
            :src="require('@/assets/images/license/R-24.png')"
          />
          <h5 class="ml-2">
            R赛照
          </h5>
        </div>
      </b-card-header>
      <b-card-body>
        <b-badge
          v-for="driver in filterDrivers.filter(x => x.license === 'R')"
          :key="driver.id"
          class="m-1"
          href="#"
          variant="secondary"
        >
          <h-link
            :id="driver.id"
            :text="driver.name"
            to="driver"
            type="driver"
          />
        </b-badge>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import Vue from 'vue'
import axios from '@axios'
import {
  BFormGroup, BInputGroup, BInputGroupPrepend, BFormInput, BBadge, BCard, BImg, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import HLink from '@/views/common/h-link.vue'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BBadge,
    BCard,
    BImg,
    BCardHeader,
    BCardBody,
    HLink,
  },
  data() {
    return {
      seachQuery: '',
      drivers: [],
    }
  },
  computed: {
    filterDrivers() {
      const seachQueryIcon = this.seachQuery.toLowerCase()
      return this.drivers.filter(item => item.name.toLowerCase().includes(seachQueryIcon))
    },
  },
  created() {
    axios.get(Vue.prototype.$Api_Driver_License_PRO)
      .then(response => {
        response.data.forEach(item => {
          const driver = {
            name: item.NickName,
            id: item.Did,
            license: 'Pro',
          }
          this.drivers.push(driver)
        })
      })
    axios.get(Vue.prototype.$Api_Driver_License_A)
      .then(response => {
        response.data.forEach(item => {
          const driver = {
            name: item.NickName,
            id: item.Did,
            license: 'A',
          }
          this.drivers.push(driver)
        })
      })
    axios.get(Vue.prototype.$Api_Driver_License_B)
      .then(response => {
        response.data.forEach(item => {
          const driver = {
            name: item.NickName,
            id: item.Did,
            license: 'B',
          }
          this.drivers.push(driver)
        })
      })
    axios.get(Vue.prototype.$Api_Driver_License_C)
      .then(response => {
        response.data.forEach(item => {
          const driver = {
            name: item.NickName,
            id: item.Did,
            license: 'C',
          }
          this.drivers.push(driver)
        })
      })
    axios.get(Vue.prototype.$Api_Driver_License_D)
      .then(response => {
        response.data.forEach(item => {
          const driver = {
            name: item.NickName,
            id: item.Did,
            license: 'D',
          }
          this.drivers.push(driver)
        })
      })
    axios.get(Vue.prototype.$Api_Driver_License_R)
      .then(response => {
        response.data.forEach(item => {
          const driver = {
            name: item.NickName,
            id: item.Did,
            license: 'R',
          }
          this.drivers.push(driver)
        })
      })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/ui-feather.scss';
</style>
